import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { QueryKeys, Routes } from '@/constants/routes'
import AuthContainer, { LoginStatusEnum } from '@/containers/common/AuthContainer'

const withAuth = (Component: React.ComponentType) => {
  const AuthWrapper = <P extends Record<string, any>>(props: P) => {
    const { replace } = useRouter()
    const { loginStatus } = AuthContainer.useContainer()

    useEffect(() => {
      if (loginStatus === LoginStatusEnum.Logout) {
        replace({
          pathname: Routes.Login,
          query: {
            [QueryKeys.LogoutFlag]: true
          }
        })
      }
    }, [loginStatus])

    if (loginStatus !== LoginStatusEnum.Login) {
      return null
    }

    return <Component {...props} />
  }

  return AuthWrapper
}

export default withAuth
