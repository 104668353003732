import { useLayoutEffect } from 'react'
import { useRouter } from 'next/router'
import { QueryKeys, Routes } from '@/constants/routes'
import AuthContainer from '@/containers/common/AuthContainer'
import withAuth from '@/hoc/withAuth'

/**
 * next.config.js 에서 모니터링 페이지 index로 영구 redirection 합니다.
 * 308 상태코드를 반환합니다.
 * @constructor
 */
const Home = () => {
  const { siteId } = AuthContainer.useContainer()
  const { replace } = useRouter()

  useLayoutEffect(() => {
    const replaceUserSite = () => {
      if (!siteId) return
      replace({
        pathname: Routes.Monitoring.Target,
        query: {
          [QueryKeys.Target]: siteId
        }
      })
    }
    replaceUserSite()
  }, [siteId])
  return null
}

export default withAuth(Home)
